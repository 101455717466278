<template>
  <section
    id="home-hero"
    :class="`col-def col-span-4 lg:col-span-12 mt-4 lg:mt-6 bg-no-repeat lg:bg-none`"
    :style="`background-image: url('${background}')`"
  >
    <div
      :class="`col-span-4 lg:col-span-6 py-8 lg:my-auto lg:bg-left-top lg:bg-no-repeat`"
      :style="
        $viewport.isGreaterOrEquals('lg') &&
        `background-image: url('${lgBackground}')`
      "
    >
      <Headline1 class="mb-4">
        <span>Kişisel sağlık </span>
        <span class="text-primary-400">danışmanınız</span>
      </Headline1>
      <Body1 class="mb-6">
        Sağlığınıza gereken önemi {{ companyName }}'yla verin. Psikolog,
        fizyoterapist ve diyetisyenlerle görüşün, içerik dünyasını keşfedin
      </Body1>
      <SalusAppDownloadButton
        class="w-60 download-button"
        id="hero-signup-flow"
      />
    </div>
    <div class="col-span-4 lg:col-span-6 lg:col-start-7 select-none">
      <div class="relative col-span-4 lg:col-span-12 aspect-square pb-8">
        <Img
          :src="`${cdn}/home-hero-visual.webp`"
          :alt="`${companyName} mobil uygulama görseli`"
          class="absolute -top-5 lg:top-0 lg:px-6 w-full aspect-[38:43]"
          loading="eager"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { COMPANY_NAME } from "~/constants.js";

export default {
  data() {
    const config = useRuntimeConfig();
    return {
      companyName: COMPANY_NAME,
      cdn: config.public.cdn,
      lgBackground: `${config.public.cdn}/home-hero-bg.webp`,
      background: `${config.public.cdn}/home-hero-bg-mobile.webp`,
    };
  },
};
</script>

<template>
  <SalusHomeWhyInfoCard id="size-ozel">
    <template v-slot:title>Size Özel </template>
    <template v-slot:content>
      Size sorulan sorulardan elde edilen bilgiler doğrultusunda,
      ihtiyaçlarınıza göre oluşturulmuş uzman ekip
    </template>
    <template v-slot:image>
      <Img
        :src="`${cdn}/why-salus-1.webp`"
        alt="Uygulama danışan testi ekranını gösteren görsel"
        width="165"
        height="196"
        class="w-full aspect-auto"
      />
    </template>
  </SalusHomeWhyInfoCard>
  <SalusHomeWhyInfoCard id="kapsamli">
    <template v-slot:title>Kapsamlı </template>
    <template v-slot:content>
      Uzmanların yanında size özel makale, video, yönlendirmeli meditasyon ve
      egzersizlerin yer aldığı içerik dünyası
    </template>
    <template v-slot:image>
      <Img
        :src="`${cdn}/why-salus-2.webp`"
        alt="Uygulama içerikler ekranını gösteren görsel"
        width="165"
        height="196"
        class="w-full aspect-auto"
      />
    </template>
  </SalusHomeWhyInfoCard>
  <SalusHomeWhyInfoCard id="bilimsel">
    <template v-slot:title>Bilimsel</template>
    <template v-slot:content>
      Dünya çapında geçerli ölçeklerin yer aldığı düzenli online check-uplar
    </template>
    <template v-slot:image>
      <Img
        :src="`${cdn}/why-salus-3.webp`"
        alt="Uygulama check-uplar ekranını gösteren görsel"
        width="165"
        height="196"
        class="w-full aspect-auto"
      />
    </template>
  </SalusHomeWhyInfoCard>
  <SalusHomeWhyInfoCard id="erisilebilir">
    <template v-slot:title>Erişilebilir</template>
    <template v-slot:content>
      Her yerden katılabileceğiniz online uzman görüşmeleri
    </template>
    <template v-slot:image>
      <Img
        :src="`${cdn}/why-salus-4.webp`"
        alt="Uygulama görüşme ekranını gösteren görsel"
        width="165"
        height="196"
        class="w-full aspect-auto"
      />
    </template>
  </SalusHomeWhyInfoCard>
</template>
<script>
export default {
  data() {
    const config = useRuntimeConfig();
    return {
      cdn: config.public.cdn,
    };
  },
};
</script>

<template>
  <section id="user-stories" class="col-span-4 lg:col-span-12 col-def" :class="class">
    <TitleStrip class="mb-7">
      <template v-slot:title>
        <span class="text-primary-400">Kullanıcı</span><span> hikayeleri</span>
      </template>
      <template v-slot:description>
        {{ companyName }} kullanıcılarının deneyimlerine ve yorumlarına göz atın.
      </template>
    </TitleStrip>
    <SalusHomeUserStoriesInfoCardGroup class="gap-y-5" />
    <SalusAppDownloadStrip />
  </section>
</template>
<script>
import { COMPANY_NAME } from "~/constants.js";
export default {
  name: "UserStories",
  props: {
    class: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    companyName: COMPANY_NAME,
  }),
};
</script>

<template>
  <section class="col-def col-span-4 lg:col-span-12">
    <label1 class="col-span-4 lg:col-start-5 text-center text-neutral-400"
      >BİZE GÜVENEN ŞİRKETLER</label1
    >
    <ul id="clients" v-if="$viewport.isGreaterOrEquals('md')">
      <li>
        <Img
          :src="`${cdn}/pg-logo.webp`"
          class="w-20 aspect-square"
          alt="pg-logosu"
        />
      </li>
      <li>
        <Img
          :src="`${cdn}/qnb-finansbank-logo.webp`"
          class="w-28 aspect-[3.25]"
          alt="qnbfinansbank-logosu"
        />
      </li>
      <li>
        <Img
          :src="`${cdn}/ing-logo.webp`"
          class="w-28 aspect-[3.25]"
          alt="ing-turkiye-logosu"
        />
      </li>
      <li>
        <Img
          :src="`${cdn}/midas-logo.webp`"
          class="w-28 aspect-[2.89]"
          alt="midas-logosu"
        />
      </li>
      <li>
        <Img
          :src="`${cdn}/cenoa-logo.webp`"
          width="112"
          class="w-28 aspect-[4.38]"
          alt="cenoa-logosu"
        />
      </li>
    </ul>
    <div v-if="$viewport.isLessThan('md') || $isMobile()" class="col-span-4">
      <ul class="flex justify-between items-center">
        <li>
          <Img
            :src="`${cdn}/qnb-finansbank-logo.webp`"
            class="w-20 aspect-[3.25]"
            alt="qnbfinansbank-logosu"
          />
        </li>
        <li>
          <Img
            :src="`${cdn}/pg-logo.webp`"
            class="w-14 aspect-square"
            alt="pg-logosu"
          />
        </li>
        <li>
          <Img
            :src="`${cdn}/ing-logo.webp`"
            class="w-20 aspect-[3.25]"
            alt="ing-logosu"
          />
        </li>
      </ul>
      <ul class="flex justify-evenly items-center">
        <li>
          <Img
            :src="`${cdn}/midas-logo.webp`"
            class="w-20 aspect-[2.89]"
            alt="midas-logosu"
          />
        </li>
        <li>
          <Img
            :src="`${cdn}/cenoa-logo.webp`"
            class="w-20 aspect-[4.38]"
            alt="cenoa-logosu"
          />
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    const config = useRuntimeConfig();
    return {
      cdn: config.public.cdn,
    };
  },
};
</script>
<style scoped>
ul#clients {
  @apply col-span-4 lg:col-span-12 flex justify-between;
}

ul#clients li {
  @apply flex flex-col justify-center;
}
</style>

<template>
  <SalusHomeExperienceStep id="experience-step-one" step="1">
    <template v-slot:title>İhtiyaçlarınızı belirliyoruz</template>
    <template v-slot:content>
      Uygulamaya girişte, bilimsel destekli check-uplar ve uzman öngörüşmeleri
      ile kapsamlı bir çalışma yaparak ihtiyaçlarınızı belirliyoruz.
    </template>
    <template v-slot:image>
      <Img
        :src="`${cdn}/experience-step-1.webp`"
        alt="Yapılacakları kontrol eden kişi illüstrasyonu"
        class="w-full aspect-auto"
      />
    </template>
  </SalusHomeExperienceStep>
  <SalusHomeExperienceStep id="experience-step-two" step="2">
    <template v-slot:title>Yolculuğunuzu kişiselleştiriyoruz</template>
    <template v-slot:content>
      Fiziksel ve mental iyi oluşunuzu desteklemek için kişiselleştirilmiş bir
      danışman ekibin ve kapsamlı içeriklerin yer aldığı, size özel bir deneyim
      tasarlıyoruz.
    </template>
    <template v-slot:image>
      <Img
        :src="`${cdn}/experience-step-2.webp`"
        alt="İki kişinin oturarak konuştukları illüstrasyon"
        class="w-full aspect-auto"
      />
    </template>
  </SalusHomeExperienceStep>
  <SalusHomeExperienceStep id="experience-step-three" step="3">
    <template v-slot:title>Sürecinizi takip ediyoruz</template>
    <template v-slot:content>
      Uzman görüşmeleri ve düzenli check-uplar doğrultusunda, değişen
      ihtiyaçlarınızı ayak uydurarak deneyiminizi güncelliyoruz.
    </template>
    <template v-slot:image>
      <Img
        :src="`${cdn}/experience-step-3.webp`"
        alt="Düşünen, ölçen, değerlendiren kişi illüstrasyonu"
        class="w-full aspect-auto"
      />
    </template>
  </SalusHomeExperienceStep>
</template>
<script>
export default {
  data() {
    const config = useRuntimeConfig();
    return {
      cdn: config.public.cdn,
    };
  },
};
</script>

<template>
  <section class="bg-neutral-variant-100 py-16">
    <section id="home-blog" class="col-span-4 lg:col-span-12 col-def">
      <TitleStrip class="mb-7">
        <template v-slot:title>
          <span>Heltia</span>
          <span class="text-primary-400"> Blog</span>
        </template>
        <template v-slot:description>
          Uzman onaylı kendine yardım kaynakları ve günlük hayatta size yol gösterebilecek
          sağlık ipuçları.
        </template>
      </TitleStrip>

      <SalusBlogPosts
        :posts="posts"
        class="col-def col-span-4 lg:col-span-8 lg:col-start-3"
        blogCardClass="col-span-4 lg:col-span-6"
      />
      <div
        id="load-more"
        class="col-span-4 lg:col-span-12 col-def flex items-center mt-7"
      >
        <Button
          label="Tümünü gör"
          class="w-60"
          type="secondary"
          @click="navigateTo('/blog')"
        />
      </div>
    </section>
  </section>
</template>

<script setup>
import { loadPosts } from "~/helpers/api/blog";

const posts = ref([]);

onMounted(() => {
  loadPosts(1, 4).then(function (posts) {
    appendPosts(posts.posts);
  });
});

const appendPosts = (items) => {
  items.forEach((post) => {
    posts.value.push(post);
  });
};
</script>

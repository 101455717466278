<template>
  <section
    id="happy-teams"
    class="col-span-4 lg:col-span-12 col-def"
    :class="class"
  >
    <div class="col-span-4 lg:col-span-12 col-def gap-0">
      <Headline2 class="col-span-4 lg:col-span-12 col-def lg:mb-2">
        <div class="col-span-4 lg:col-span-8 lg:col-start-3 text-center">
          <span>Sağlıklı ve </span>
          <span class="text-primary-400">mutlu</span><span> ekipler için</span>
        </div>
      </Headline2>
      <Body1
        class="col-span-4 lg:col-span-8 lg:col-start-3 lg:mb-12 text-center"
      >
        {{ companyName }} şirketlere kapsayıcı ve etkili bir yan hak paketi
        sunar. Uzmanlar eşliğinde ilerleyen iyileştirici ve önleyici sağlık
        hizmetleri ile, çalışanlarınıza kapsamlı bir destek sunabilirsiniz.
      </Body1>
    </div>
    <!-- <SalusHomeHappyTeamsInfoCardGroup class="gap-y-5" /> -->
    <div class="col-span-4 lg:col-start-5 flex justify-center">
      <Button
        label="Detaylı bilgi"
        type="secondary"
        class="w-[240px] mx-auto"
        @click="navigateTo({ path: '/sirketler-icin' })"
      />
    </div>
  </section>
</template>
<script>
import { COMPANY_NAME } from "~/constants.js";

export default {
  name: "HappyTeams",
  props: {
    class: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      companyName: COMPANY_NAME,
    };
  },
};
</script>

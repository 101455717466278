<template>
  <section
    id="salus-experience-in-3-steps"
    class="col-span-4 lg:col-span-12 col-def lg:gap-y-0"
    :class="class"
  >
    <Headline2 class="col-span-4 lg:col-span-12 col-def lg:mb-12">
      <div class="col-span-4 lg:col-span-6 lg:col-start-4 text-center">
        <span class="text-primary-400">3 Adımda</span> <span> {{ companyName }} deneyimi</span>
      </div>
    </Headline2>
    <SalusHomeExperienceSteps />
    <SalusAppDownloadStrip />
  </section>
</template>

<script>
import { COMPANY_NAME } from "~/constants.js";

export default {
  name: "SalusExperienceIn3Steps",
  props: {
    class: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    companyName: COMPANY_NAME,
  }),
};
</script>

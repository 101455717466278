<template>
  <article
    :id="id"
    class="col-span-4 lg:col-span-3 px-4 pt-8 bg-primary-200 rounded-2xl flex flex-col justify-between"
  >
    <div>
      <Headline3 class="text-primary-400 mb-2">
        <slot name="title" />
      </Headline3>
      <Body1 class="mb-6">
        <slot name="content" />
      </Body1>
    </div>

    <slot name="image" />
  </article>
</template>
<script>
export default {
  name: "WhyInfoCard",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
};
</script>

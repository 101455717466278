<template>
  <article
    :id="id"
    class="px-6 py-8 bg-secondary-100 border border-secondary-200 rounded-2xl flex flex-col justify-between"
  >
    <div>
      <Headline4 class="text-secondary-400 mb-3">
        <slot name="title" />
      </Headline4>
      <Body1>
        <slot name="content" />
      </Body1>
    </div>
  </article>
</template>
<script>
export default {
  name: "UserStoriesInfoCard",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <section id="salus-professional" class="bg-neutral-600 text-white-500 py-16">
    <div class="col-def col-span-4 lg:col-span-12">
      <div class="col-def-full gap-0 col-span-4 lg:col-span-12">
        <TitleStrip class="mb-7">
          <template v-slot:title>
            <span>{{ companyName }} uzmanı olun</span>
          </template>
          <template v-slot:description>
            {{ companyName }} ile esnek bir şekilde çalışabilir, bilgi birikimi
            ve deneyimlerinizle {{ companyName }}'nın gelişmesine katkı
            sağlayabilirsiniz.
          </template>
        </TitleStrip>
      </div>
      <Img
        :src="`${cdn}/salus-professional.webp`"
        alt="Salus uzmanı illüstrasyonu"
        class="col-span-4 lg:col-start-3 w-full aspect-square"
      />
      <ul class="salus-task-list col-span-4 my-auto" :style="listStyle">
        <li><Body1>Yetkin, kaliteli bir topluluğun parçası olun</Body1></li>
        <li><Body1>Danışan ağınızı verimli bir şekilde genişletin</Body1></li>
        <li><Body1>Hem bizi hem kendinizi bir adım ileriye taşıyın</Body1></li>
      </ul>
      <div class="col-span-4 lg:col-start-5 mt-12 flex justify-center">
        <Button
          label="Detaylı bilgi"
          type="secondary"
          theme="dark"
          class="w-[240px] mx-auto text-white-500"
          @click="navigateTo('/uzmanlar-icin')"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { COMPANY_NAME } from "~/constants.js";

export default {
  data() {
    const config = useRuntimeConfig();
    const cdn = config.public.cdn;
    return {
      companyName: COMPANY_NAME,
      cdn: cdn,
      illustrationIcon: `${cdn}/task-icon-illustration.webp`,
    };
  },
  computed: {
    listStyle() {
      return {
        "--icon-url": `url(${this.illustrationIcon})`,
      };
    },
  },
};
</script>
<style scoped>
.salus-task-list li {
  @apply flex items-center mb-10;
}
.salus-task-list li:last-child {
  @apply mb-0;
}
.salus-task-list li:before {
  @apply mr-2;
  content: var(--icon-url);
}
</style>

<template>
  <SalusHomeHero />
  <div class="h-16" />
  <SalusClients />
  <div class="h-16" />
  <SalusHomeWhy />
  <div class="h-16" />
  <SalusHomeExperience />
  <div class="h-16" />
  <SalusHomeUserStories />
  <div class="h-16" />
  <SalusHomeHappyTeams />
  <div class="h-16" />
  <SalusHomeBlog />
  <div class="h-16" />
  <SalusHomeProfessional />
</template>

<script setup>
useSeoMeta({
  title: "Heltia - Online Terapi ve Diyet Uygulaması",
  titleTemplate: "",
  description:
    "Heltia'da 450'den fazla online psikolog, diyetisyen, fizyoterapist, spor eğitmeni sizi bekliyor! Üyelik oluşturun, online terapi seansınızı hemen planlayın.",
});
</script>

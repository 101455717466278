<template>
  <section
    id="why-salus"
    class="col-span-4 lg:col-span-12 col-def lg:gap-y-0"
    :class="class"
  >
    <Headline2 class="col-span-4 lg:col-span-12 col-def lg:mb-12">
      <div class="col-span-4 lg:col-start-5 text-center">
        <span class="text-primary-400">Neden</span> <span> {{ companyName }}?</span>
      </div>
    </Headline2>
    <SalusHomeWhyInfoCardGroup />
    <SalusAppDownloadStrip />
  </section>
</template>
<script>
import { COMPANY_NAME } from "~/constants.js";
export default {
  name: "WhySalus",
  props: {
    class: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    companyName: COMPANY_NAME,
  }),
};
</script>

<template>
  <section id="userstory-infocard-group" class="col-span-4 lg:col-span-12 col-def-full">
    <SalusHomeUserStoriesInfoCard id="story-1" class="col-span-4 lg:col-span-6">
      <template v-slot:title
        >“Hamilelik ve doğum sonrasını, {{ companyName }}'nın sağlamış olduğu imkanlarla çok daha rahat
        yönetebildim.”</template
      >
      <template v-slot:content>
        Uzman psikoloğum sayesinde anlaşıldığımı hissetmek, kendimi tanımak ve duygularımı
        anlamlandırmak duygusal gelişimim açısından çok faydalı oldu. Aynı zamanda
        bebeğimin bakımı, uyku ve emzirme konularında çok fazla soru işareti taşırken,
        eşleştiğim doula sayesinde bu sorularıma da cevap buldum.
      </template>
    </SalusHomeUserStoriesInfoCard>
    <SalusHomeUserStoriesInfoCard id="story-2" class="col-span-4 lg:col-span-6">
      <template v-slot:title
        >“Cevaplarını bildiğimizi sandığımız konularda bile bir uzmanla çalışmanın
        etkisini {{ companyName }} ile keşfettim.”</template
      >
      <template v-slot:content>
        Sayısı gitgide artan, bir türlü tamamlayamadığım hedeflerimle boğuşurken kişisel
        danışmanımla yaptığımız çalışmalar sonucu hayatımın kontrolünü tekrar kazandığımı
        hissettim.
      </template>
    </SalusHomeUserStoriesInfoCard>
    <SalusHomeUserStoriesInfoCard id="story-3" class="col-span-4 lg:col-span-6">
      <template v-slot:title
        >“Psikolojik desteğe ihtiyacım olduğunu kabullenmek benim için çok zor ve uzun bir
        süreçti.”</template
      >
      <template v-slot:content>
        Yardım almaya hazır hissettiğimde {{ companyName }} sayesinde “Nereden yardım almalıyım?
        Kiminle görüşmeliyim?” gibi sorularla boğulmadan, bana uygun bir uzmanla çalışmaya
        başladım. Beni anlamaya çalışan ve önemsendiğimi hissettiğim bir ortamda, doğru
        uzmanlarla artık kendimi mutlu ve huzurlu hissetmeye başladım.
      </template>
    </SalusHomeUserStoriesInfoCard>
    <SalusHomeUserStoriesInfoCard id="story-4" class="col-span-4 lg:col-span-6">
      <template v-slot:title
        >“{{ companyName }} yolculuğum boyunca ihtiyaç duyduğum farklı konularda, birden fazla uzman
        ile çalıştım.”</template
      >
      <template v-slot:content>
        Her konu için, özenle seçildiği belli olan kadrosu arasından; benim ihtiyaçlarım,
        geçmişim ve sorunlarımla nokta atışı gibi eşleşen uzmanlara yönlendirildim. Bana
        her açıdan, en iyi şekilde destek olacağına güvendiğim bu uygulamayla
        karşılaştığım için çok minnettarım.
      </template>
    </SalusHomeUserStoriesInfoCard>
  </section>
</template>
<script>
import { COMPANY_NAME } from "~/constants.js";
export default {
  data() {
    return {
      companyName: COMPANY_NAME,
    };
  },
};
</script>
<template>
  <div class="col-span-4 lg:col-span-12 col-def gap-y-0">
    <Headline2 class="col-span-4 lg:col-span-12 col-def lg:mb-2">
      <div class="col-span-4 lg:col-span-6 lg:col-start-4 text-center">
        <slot name="title" />
      </div>
    </Headline2>
    <Body1 class="col-span-4 lg:col-span-12 text-center">
      <slot name="description" />
    </Body1>
  </div>
</template>

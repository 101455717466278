<template>
  <section id="blog-posts" :class="$props.class">
    <article
      class="bg-white-500 rounded-2xl p-3 shadow"
      :class="$props.blogCardClass"
      :key="`blog-content-${post.title}`"
      v-for="post in posts"
    >
      <NuxtLink
        class="cursor-pointer"
        :to="`/blog/${post.slug}`"
        target="_blank"
      >
        <Img
          :src="post.coverImage.small"
          :alt="post.title"
          class="rounded-lg w-full object-cover mb-4 aspect-[3/2]"
        />
        <div
          class="col-span-4 lg:col-span-12 mb-3 flex flex-row align-middle text-neutral-600"
        >
          <Body2> {{ post.publishedAt }}</Body2>
          <Body2 v-if="post.category != undefined" class="flex items-center">
            <span class="mx-2 text-[4px] icon-placeholder"></span>
            {{ post.category.title }}
          </Body2>
        </div>
        <Headline4 class="text-neutral-600 font-normal mb-1 line-clamp-2">
          {{ post.title }}
        </Headline4>
        <Body2 class="text-neutral-500 mb-3 line-clamp-3">
          {{ post.summary }}
        </Body2>
        <Label2 class="mr-2 text-primary-400 flex cursor-pointer"
          ><span>Makaleyi oku</span><span class="icon-arrow-right ml-3"></span
        ></Label2>
      </NuxtLink>
    </article>
  </section>
</template>
<script>
export default {
  props: {
    posts: {
      type: Array,
      required: true,
    },
    blogCardClass: {
      type: String,
      default: null,
    },
    class: {
      type: String,
      default: null,
    },
  },
};
</script>

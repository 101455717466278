<template>
  <article
    :id="id"
    class="col-span-4 px-6 py-8 bg-neutral-variant-100 border border-neutral-variant-300 rounded-2xl flex flex-col justify-between relative overflow-hidden"
  >
    <div class="absolute w-[68px] top-[-7px] right-0 text-center">
      <span class="font-serif text-[120px] text-neutral-variant-500 leading-[90px]">
        {{ step }}
      </span>
    </div>
    <div>
      <Headline3 class="mb-2">
        <slot name="title" />
      </Headline3>
      <Body1 class="mb-6">
        <slot name="content" />
      </Body1>
    </div>
    <slot name="image" />
  </article>
</template>
<script>
export default {
  name: "ExperienceStep",
  props: {
    id: {
      type: String,
      default: "",
    },
    step: {
      type: String,
      default: "",
    },
  },
};
</script>
